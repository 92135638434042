module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-195436344-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laforga | Shopify Agency","short_name":"Laforga | Shopify Agency","start_url":"/","background_color":"#134354","theme_color":"#134354","display":"standalone","icon":"src/images/laforga-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f168b6658bb3e8b5bb349e0aaacfa4e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["*"],"importWorkboxFrom":"local","globDirectory":"public","globPatterns":["*/**"],"cacheId":"gatsby-plugin-offline","skipWaiting":true,"clientsClaim":true,"directoryIndex":"index.html"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
